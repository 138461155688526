import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useRouter } from 'next/router';
import { styled } from '@mui/material/styles';
import {
  MenuItem,
  InputLabel as MuiLabel,
  FormControl as MuiFormControl,
  TextField,
  Select,
  Button,
} from '@mui/material';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';

import { InputFieldSkeleton } from './InputFieldSkeleton';
import {
  PURPOSES_OPTIONS,
  TERMS_OPTIONS,
  addDotInAmount,
  removeDotInAmount,
  sendAmountValidationError,
} from 'lib/offers/filters';
import { InputNames, useLoanSelection } from 'contexts/LoanSelectionContext';
import {
  DEFAULT_AMOUNT,
  DEFAULT_MORTGAGE_AMOUNT,
  LABEL,
  REAL_ESTATE_VALUE,
} from 'lib/constants/offerFilter';

import { usePageConfig } from 'contexts/PageConfigContext';
import { formatNumber, formatNumberCurrency } from 'lib/helpers/formatNumber';

import { useFetchPaasWithoutDebtors } from 'lib/regroute/useFetchPaas';
import { getHandleSubmit } from 'lib/helpers/submitHelper';
import { useSubmit } from 'lib/regroute/useSubmit';
import { trackLoanSelectorChange } from 'lib/tracking/helper';
import { getAdvertisementId } from 'lib/helpers/advertisementId';
import { useBestOffer } from 'contexts/BestOfferContext';
import { filterBestOffer } from 'lib/best-offer/filterBestOffer';
import { useOffers } from 'contexts/OffersContext';

const { colors } = finanzcheck;

const Wrapper = styled('div')`
  .MuiTypography-root {
    margin: 8px 0;
  }
`;

const Form = styled('form')`
  min-width: 250px;
`;

const FormControl = styled(MuiFormControl)`
  width: 100%;
  margin: 0 auto 24px;
`;

const StyledLabel = styled(MuiLabel)(({ theme }) => ({
  top: theme.spacing(1.5),
  left: `-${theme.spacing(0.25)}`,
}));

const StyledTextField = styled(TextField)`
  height: 100%;
`;

const StyledButton = styled(Button)`
  text-transform: none;
  flex-direction: column;
  height: 64px;
`;

const ButtonMainContainer = styled('div')`
  display: flex;
  font-size: 20px;
`;

const ButtonMainText = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(0.25),
  fontSize: '16px',
}));

const ButtonSubText = styled('div')`
  font-size: 12px;
`;

const BestOfferBox = styled('div')(({ theme }) => ({
  backgroundColor: `${colors.primary[300]}`,
  borderRadius: theme.shape.borderRadius,
  minHeight: '56px',
  padding: `1px ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const BestOfferHeadLine = styled('div')(({}) => ({
  color: 'black',
  fontSize: '.75rem',
  lineHeight: '1.5',
}));

const Error = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  background: 'rgb(255, 182, 179)', // no color in theme
  fontSize: '15px',
  fontWeight: 'bold',
}));

const InputEndAdornment = styled('div')(({}) => ({
  pointerEvents: 'none',
}));

const MonthlyRate = styled('span')`
  color: black;
  font-size: '1.1rem';
  font-weight: 700;
`;

const MonthlyRateText = styled('div')(() => ({
  color: 'black',
  fontSize: '0.75rem',
}));

const EffectiveInterestRate = styled('span')(() => ({
  color: `${colors.primary.main}`,
  fontSize: '1.1rem',
  fontWeight: 700,
}));

const EffectiveInterestRateText = styled('span')(() => ({
  color: `${colors.primary.main}`,
  fontSize: '0.75rem',
}));

const BestOfferDetailContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: baseline;
`;

const Sup = styled('span')`
  vertical-align: super;
  font-size: 60%;
`;
interface LoanSelectorProps {
  indexOnBestOffer: string | undefined;
}

export const LoanSelector = (props: LoanSelectorProps) => {
  const { indexOnBestOffer = '1' } = props;

  const { loanSelection, setLoanSelection } = useLoanSelection();

  const { asPath } = useRouter();
  const advertisementId = getAdvertisementId(asPath);
  const { offers } = useOffers();
  const { sendPaasData } = useFetchPaasWithoutDebtors(advertisementId);

  const [isClientSide, setIsClientSide] = useState(false);

  const { bestOffer, setBestOffer } = useBestOffer();

  const pageConfigContext = usePageConfig();

  useEffect(() => {
    setIsClientSide(true);
  }, []); // Intentionally empty array, we only want to run this once

  useEffect(() => {
    // Set initial best offer
    const bestOffer = filterBestOffer(offers, loanSelection);
    bestOffer && setBestOffer(bestOffer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loanSelection.amount,
    loanSelection.term,
    loanSelection.purpose,
    offers.length,
  ]);

  const [amountValidationError, setAmountValidationError] = useState('');
  const numberInputs = [InputNames.Amount];

  const handleChange = (selectName: InputNames) => async (event: any) => {
    const { value }: { value: string } = event.target;
    let newValue: number | string = value;
    const isNumberInput = numberInputs.includes(selectName);

    if (isNumberInput) {
      const amount = removeDotInAmount(value);
      const isAmountANumber = typeof amount === 'number' && !isNaN(amount);
      newValue = isAmountANumber ? amount : '0';
      const amountError = sendAmountValidationError(amount || 0);
      setAmountValidationError(amountError);
    }

    trackLoanSelectorChange(selectName, newValue);

    /* handling for mortage */
    if (
      selectName === InputNames.Purpose &&
      loanSelection.purpose !== value &&
      loanSelection.amount === DEFAULT_MORTGAGE_AMOUNT &&
      value === REAL_ESTATE_VALUE
    ) {
      const loanSelectionValue = {
        ...loanSelection,
        amount: DEFAULT_MORTGAGE_AMOUNT,
        purpose: REAL_ESTATE_VALUE,
      };
      setLoanSelection(loanSelectionValue);
      setBestOffer(filterBestOffer(offers, loanSelectionValue));
      return;
    }

    /* handling for default amounts */
    const hasPurposeJustChanged =
      selectName === InputNames.Purpose && loanSelection.purpose !== value;
    const isAmountStillDefault =
      loanSelection.amount === DEFAULT_MORTGAGE_AMOUNT ||
      loanSelection.amount === DEFAULT_AMOUNT;

    if (hasPurposeJustChanged && isAmountStillDefault) {
      const loanSelectionValue = {
        ...loanSelection,
        amount:
          value === REAL_ESTATE_VALUE
            ? DEFAULT_MORTGAGE_AMOUNT
            : DEFAULT_AMOUNT,
        [selectName as string]: newValue,
      };

      setLoanSelection(loanSelectionValue);
      setBestOffer(filterBestOffer(offers, loanSelectionValue));
      return;
    }

    const loanSelectionValue = {
      ...loanSelection,
      [selectName]: newValue,
    };

    setLoanSelection(loanSelectionValue);
    setBestOffer(filterBestOffer(offers, loanSelectionValue));
  };

  const handleFocus = (event: any) => event.target.select();

  const handleBlur = (event: any) => {
    const amountError = sendAmountValidationError(event.target.value);
    setAmountValidationError(amountError);
  };

  const submitHandler = getHandleSubmit(
    loanSelection,
    sendPaasData,
    pageConfigContext,
    bestOffer,
  );
  const [isSubmitting, handleSubmitLoanSelector] = useSubmit(submitHandler);

  return (
    <Wrapper>
      <Form>
        <FormControl variant="outlined">
          {isClientSide ? (
            <StyledTextField
              type="tel"
              label={LABEL.Amount}
              id={InputNames.Amount}
              data-testid={InputNames.Amount}
              color="primary"
              fullWidth
              variant="filled"
              value={addDotInAmount(loanSelection[InputNames.Amount])}
              onChange={handleChange(InputNames.Amount)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              inputProps={{
                pattern: 'd*',
                inputMode: 'decimal',
              }}
              InputProps={{
                endAdornment: <InputEndAdornment>EURO</InputEndAdornment>,
              }}
            />
          ) : (
            <InputFieldSkeleton />
          )}
        </FormControl>
        <br />
        <FormControl variant="outlined" style={{ height: '60px' }}>
          <StyledLabel id={InputNames.Term}>{LABEL.Term}</StyledLabel>
          {isClientSide ? (
            <Select
              fullWidth
              label={LABEL.Term}
              variant="filled"
              labelId={InputNames.Term}
              data-testid={InputNames.Term}
              value={loanSelection[InputNames.Term]}
              onChange={handleChange(InputNames.Term)}
              MenuProps={{
                sx: {
                  '&& .Mui-selected': {
                    backgroundColor: 'rgba(240, 145, 3, 0.12) !important',
                  },
                },
              }}
            >
              {TERMS_OPTIONS.map((termOption) => (
                <MenuItem key={termOption.value} value={termOption.value}>
                  {termOption.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <InputFieldSkeleton />
          )}
        </FormControl>
        <br />
        <FormControl variant="outlined" style={{ height: '60px' }}>
          <StyledLabel id={InputNames.Purpose}>{LABEL.Purpose}</StyledLabel>
          {isClientSide ? (
            <Select
              fullWidth
              label={LABEL.Purpose}
              variant="filled"
              labelId={InputNames.Purpose}
              data-testid={InputNames.Purpose}
              value={loanSelection[InputNames.Purpose]}
              onChange={handleChange(InputNames.Purpose)}
              MenuProps={{
                PaperProps: {
                  style: {
                    minHeight: 42 * 10,
                  },
                },
                sx: {
                  '&& .Mui-selected': {
                    backgroundColor: 'rgba(240, 145, 3, 0.12) !important',
                  },
                },
              }}
            >
              {PURPOSES_OPTIONS.map((purposeOption) => (
                <MenuItem key={purposeOption.value} value={purposeOption.value}>
                  {purposeOption.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <InputFieldSkeleton />
          )}
        </FormControl>
        <FormControl variant="outlined" style={{ height: '60px' }}>
          {isClientSide && amountValidationError && (
            <Error>{amountValidationError}</Error>
          )}
          {isClientSide && bestOffer && (
            <BestOfferBox>
              <BestOfferHeadLine>
                Günstigster Kredit aus über 60 Angeboten ab:
              </BestOfferHeadLine>
              <BestOfferDetailContainer>
                <MonthlyRate>
                  {formatNumberCurrency(bestOffer.monthlyRate)}
                </MonthlyRate>
                <MonthlyRateText> Mtl. Rate</MonthlyRateText>
                <EffectiveInterestRate>
                  {formatNumber(bestOffer.condition.effectiveInterestRate)}%
                  <Sup>{indexOnBestOffer}</Sup>
                </EffectiveInterestRate>
                <EffectiveInterestRateText>
                  {' '}
                  Eff. Jahreszins
                </EffectiveInterestRateText>
              </BestOfferDetailContainer>
            </BestOfferBox>
          )}
        </FormControl>
        <StyledButton
          data-test-id="CTA"
          variant="contained"
          color="secondary"
          type="button"
          fullWidth
          onClick={handleSubmitLoanSelector as any}
          disabled={isSubmitting as any}
        >
          <ButtonMainContainer>
            <ButtonMainText>Kreditvergleich starten</ButtonMainText>
            <AiOutlineArrowRight />
          </ButtonMainContainer>
          <ButtonSubText>(Kostenlos und unverbindlich)</ButtonSubText>
        </StyledButton>
      </Form>
    </Wrapper>
  );
};
